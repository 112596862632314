import React from 'react'
import './Home.sass'
import Hero from '../../components/Hero/Hero'
import Catalog from '../../components/Catalog/Catalog'
import Calc from '../../components/Calc/Calc'
import About from '../../components/About/About'
import Benefits from '../../components/Benefits/Benefits'
import Feeds from '../../components/Feeds/Feeds'
import Portfolio from '../../components/Portfolio/Portfolio'
import ContactUs from '../../components/ContactUs/ContactUs'

function Home() {
  return (
    <div className="Home">
      <Hero />
      <Catalog />
      <Calc />
      <About />
      <Benefits />
      <Feeds />
      <Portfolio />
      <ContactUs />
    </div>
  )
}

export default Home
