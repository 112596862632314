import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './MachineCatalog.sass'
import { withRouter } from 'react-router-dom'
import { cats } from '../Catalog/data'
import { find } from 'lodash'
import Button from '../../ui/Button/Button'
import Img from '../../ui/Img/Img'
import InfoLine from '../../ui/InfoLine/InfoLine'

function MachineCatalog(router) {
  const catId = router.match.params.id
  const catData = find(cats, ['id', catId])

  return (
    <section className="MachineCatalog">
      <SectionTitle title={catData.categoryTitle} />
      <div className="MachineCatalog-Container">
        {catData.machines.map((m) => (
          <MachineTile {...m} />
        ))}
      </div>
    </section>
  )
}

function MachineTile({ avatar, title, infoBlock, quantity }) {
  return (
    <div className="MachineTile">
      <div className="Avatar">
        <Img src={avatar} />
        <span className="Quantity">{quantity} ед.</span>
      </div>
      <div className="MachineTile-Container">
        <p className="Title">{title}</p>
        {infoBlock}
        <InfoLine title="Мин. срок аренды" content="2 ч" />
        <Button
          title="Арендовать технику"
          icon="arrow-right"
          iconPosition="right"
          theme="solid"
          fill="blue"
        />
      </div>
    </div>
  )
}

export default withRouter(MachineCatalog)
