import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import deco from '../Hero/whiteTri.svg'
import './Portfolio.sass'
import ImageGallery from 'react-image-gallery'
import t1 from './assets/1.jpg'
import t2 from './assets/2.jpg'
import t3 from './assets/3.jpg'
import t4 from './assets/4.jpg'
import t5 from './assets/5.jpg'
import t6 from './assets/6.jpg'
import t7 from './assets/7.jpg'
import t8 from './assets/8.jpg'
import t9 from './assets/9.jpg'
import t10 from './assets/10.jpg'
import t11 from './assets/11.jpg'
import t12 from './assets/12.jpg'
import t13 from './assets/13.jpg'
import t14 from './assets/14.jpg'
import t15 from './assets/sld1.jpg'
import t16 from './assets/sld2.jpg'
import Icon from '../../ui/Icon/Icon'

function Portfolio() {
  const images = [
    {
      original: t15,
      thumbnail: t15,
    },
    {
      original: t16,
      thumbnail: t16,
    },
    {
      original: t1,
      thumbnail: t1,
    },
    {
      original: t2,
      thumbnail: t2,
    },
    {
      original: t3,
      thumbnail: t3,
    },
    {
      original: t4,
      thumbnail: t4,
    },
    {
      original: t5,
      thumbnail: t5,
    },
    {
      original: t6,
      thumbnail: t6,
    },
    {
      original: t7,
      thumbnail: t7,
    },
    {
      original: t8,
      thumbnail: t8,
    },
    {
      original: t9,
      thumbnail: t9,
    },
    {
      original: t10,
      thumbnail: t10,
    },
    {
      original: t11,
      thumbnail: t11,
    },
    {
      original: t12,
      thumbnail: t12,
    },
    {
      original: t13,
      thumbnail: t13,
    },
    {
      original: t14,
      thumbnail: t14,
    },
  ]

  return (
    <div id="portfolio" className="Portfolio DefaultSection">
      <div className="DecoTri Tri1" style={{ background: `url(${deco})` }} />
      <div className="DecoTri Tri2" style={{ background: `url(${deco})` }} />
      <SectionTitle title="Портфолио работ" theme="white" />
      <div className="Portfolio-Container">
        <div className="PortfolioSlider">
          <ImageGallery
            items={images.map((i) => ({ ...i, originalClass: 'PortfolioImg' }))}
            autoPlay
            showFullscreenButton={false}
            showPlayButton={false}
            renderLeftNav={(onClick, disabled) => (
              <div
                className="SNav SNav-Left"
                onClick={onClick}
                disabled={disabled}
              >
                <Icon name="angle-left" weight="solid" />
              </div>
            )}
            renderRightNav={(onClick, disabled) => (
              <div
                className="SNav SNav-Right"
                onClick={onClick}
                disabled={disabled}
              >
                <Icon name="angle-right" weight="solid" />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default Portfolio
