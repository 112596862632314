import React from 'react'
import './MachinePage.sass'
import Hero from '../../components/Hero/Hero'
import About from '../../components/About/About'
import Benefits from '../../components/Benefits/Benefits'
import Feeds from '../../components/Feeds/Feeds'
import Portfolio from '../../components/Portfolio/Portfolio'
import ContactUs from '../../components/ContactUs/ContactUs'
import MachineCatalog from '../MachineCatalog/MachineCatalog'

function MachinePage() {
  return (
    <div className="Home">
      <Hero />
      <MachineCatalog />
      <About />
      <Benefits />
      <Feeds />
      <Portfolio />
      <ContactUs />
    </div>
  )
}

export default MachinePage
