import React, { useEffect, createContext, useState } from 'react'
import { Route } from 'react-router-dom'
import Home from './pages/Home/Home'
import SitePage from './pages/SitePage/SitePage'
import { firebase } from './config/firebase'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
import CookieNotification from './components/CookieNotification/CookieNotification'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import MachinePage from './components/MachinePage/MachinePage'

export const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [showContactUs, setShowContactUs] = useState({
    show: false,
    title: '',
    emailSubject: '',
  })
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'ru')

  useEffect(() => {
    const userStateListener = firebase.auth().onAuthStateChanged(setUser)
    return () => {
      userStateListener()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{ user, showContactUs, setShowContactUs, lang, setLang }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <SitePage>
        <Route path="/" exact component={Home} />
        <Route path="/arenda/:id" exact component={MachinePage} />
        {/*
        <Route
          path={['/manage-project', '/manage-project/:id']}
          exact
          component={ManageProjectPage}
        />
        <Route path="/admin" exact component={Auth} /> */}
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
      </SitePage>
      <CookieNotification />
    </AuthProvider>
  )
}

export default App
