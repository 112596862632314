import React from 'react'
import './Benefits.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Icon from '../../ui/Icon/Icon'
import deco from '../Hero/whiteTri.svg'

function Benefits() {
  const bArray = [
    {
      icon: 'truck-container',
      title: 'Собственный современный автопарк',
      description:
        'В нашем автопарке только современные и надежные модели спецтехники, которые регулярно проходят предусмотренное регламентом ТО',
    },
    {
      icon: 'ruble-sign',
      title: 'Низкие цены',
      description:
        'Мы установили самые низкие цены, благодаря чему аренда спецтехники стала еще более доступной',
    },
    {
      icon: 'shield-check',
      title: 'фиксированные тарифы',
      description:
        'Стоимость аренды спецтехники тарифицируется по единому тарифу не зависимо от праздничных и выходных дней',
    },
    {
      icon: 'thumbs-up',
      title: 'полностью готовая к эксплуатации техника',
      description:
        'В стоимость аренды уже входит стоимость ГСМ и других расходных материалов, связанных с обслуживанием техники',
    },
    {
      icon: 'users',
      title: 'Квалифицированный персонал',
      description:
        'Оператор спецтехники - это высококвалифицированный сотрудник, который прошел аттестацию и обладает большим опытом работ в нашей компании',
    },
    {
      icon: 'clock',
      title: 'Заказ аренды спецтехники 24/7',
      description: 'Мы ценим Ваше время и деньги, поэтому готовы работать 24/7',
    },
  ]

  return (
    <section id="info" className="Benefits DefaultSection">
      <div className="DecoTri Tri1" style={{ background: `url(${deco})` }} />
      <div className="DecoTri Tri2" style={{ background: `url(${deco})` }} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Наши преимущества" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="Benefits-Container">
              {bArray.map((b) => (
                <Benefit {...b} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Benefit({ icon, title, description }) {
  return (
    <div className="Benefit">
      <div className="Icon-Container">
        <Icon name={icon} />
      </div>
      <p className="Title">{title}</p>
      <p className="Description">{description}</p>
    </div>
  )
}

export default Benefits
