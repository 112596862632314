import InfoLine from '../../ui/InfoLine/InfoLine'
import volvospol from './assets/samosval/volvospol.jpg'
import sitrak from './assets/samosval/sitrak.jpg'
import scania from './assets/samosval/scania.jpg'
import volvob from './assets/samosval/volvob.jpg'

import a1 from './assets/a1.jpg'
import a3 from './assets/a3.jpg'
import a4 from './assets/a4.jpg'
import a5 from './assets/a5.jpg'

import kato from './assets/kran/kato.jpg'
import kato2 from './assets/kran/kato2.jpg'
import hyundai330 from './assets/ekskavator/hyundai330.jpg'
import hyundai330g from './assets/ekskavator/hyundai330g.jpg'
import volvo300d from './assets/ekskavator/volvo300d.jpg'
import h290 from './assets/ekskavator/h290.jpg'
import lieb from './assets/ekskavator/lieb.jpg'
import h300g from './assets/ekskavator/h300g.jpg'
import hit330 from './assets/ekskavator/hit330.jpg'
import ural from './assets/buldozer/ural.jpg'

import sdlg from './assets/pogruzchik/sdlg.jpg'
import l550 from './assets/pogruzchik/l550.jpg'
import l120f from './assets/pogruzchik/l120f.jpg'
import l60f from './assets/pogruzchik/l60f.jpg'
import case3 from './assets/pogruzchik/case3.jpg'
import sany330 from './assets/ekskavator/sany330.jpg'
import sino330 from './assets/ekskavator/sino330.jpg'
import volvo180c from './assets/ekskavator/volvo180c.jpg'
import graider from './assets/graider/graider.jpg'
import sh from './assets/shalanda/shalanda.jpg'

import t1 from './assets/trall/t1.jpg'
import katok from './assets/katok/k1.jpg'

export const cats = [
  {
    id: 'samosval',
    title: 'Самосвалы',
    avatar: sitrak,
    heroTitle: 'Аренда самосвала в Мурманске и области',
    categoryTitle: 'Арендовать самосвал',
    machines: [
      {
        title: 'Самосвал Volvo 30т',
        quantity: 3,
        avatar: volvob,
        infoBlock: (
          <>
            <InfoLine title="Объем кузова" content="16м3" />
            <InfoLine title="Грузоподъемность" content="30 т" />
          </>
        ),
      },
      {
        title: 'Самосвал Sitrak 40т',
        quantity: 3,
        avatar: sitrak,
        infoBlock: (
          <>
            <InfoLine title="Объем кузова" content="23м3" />
            <InfoLine title="Грузоподъемность" content="40 т" />
          </>
        ),
      },
      {
        title: 'Самосвал Sitrak 30т',
        quantity: 2,
        avatar: sitrak,
        infoBlock: (
          <>
            <InfoLine title="Объем кузова" content="20м3" />
            <InfoLine title="Грузоподъемность" content="30 т" />
          </>
        ),
      },
      {
        title: 'Самосвал Volvo',
        avatar: a1,
        quantity: 3,
        infoBlock: (
          <>
            <InfoLine title="Объем кузова" content="16м3" />
            <InfoLine title="Грузоподъемность" content="30 т" />
          </>
        ),
      },
      {
        title: 'Самосвал Scania',
        quantity: 2,
        avatar: scania,
        infoBlock: (
          <>
            <InfoLine title="Объем кузова" content="16м3" />
            <InfoLine title="Грузоподъемность" content="30 т" />
          </>
        ),
      },
    ],
  },
  {
    id: 'tyagach',
    title: 'Тягач с полуприцепом',
    avatar: volvospol,
    heroTitle: 'Аренда тягача с полуприцепом в Мурманске и области',
    categoryTitle: 'Арендовать тягач с полуприцепом',
    machines: [
      {
        title: 'Тягач Volvo с полуприцепом',
        avatar: volvospol,
        quantity: 2,
        infoBlock: (
          <>
            <InfoLine title="Объем кузова" content="32м3" />
            <InfoLine title="Грузоподъемность" content="50 т" />
          </>
        ),
      },
    ],
  },
  {
    id: 'ekskavator',
    title: 'Экскаваторы',
    avatar: sany330,
    heroTitle: 'Аренда экскаватора в Мурманске и области',
    categoryTitle: 'Арендовать экскаватор',
    machines: [
      {
        title: 'Экскаватор Sany SY330H',
        avatar: sany330,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Ковш погрузочный" content="1.65м3" />
            <InfoLine title="Глубина копания" content="до 7м" />
          </>
        ),
      },
      {
        title: 'Экскаватор Sinomach GE330H',
        avatar: sino330,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Ковш погрузочный" content="1.6м3" />
            <InfoLine title="Глубина копания" content="до 8м" />
          </>
        ),
      },
      {
        title: 'Экскаватор Volvo 300 D',
        avatar: volvo300d,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Ковш погрузочный" content="1.8м3" />
          </>
        ),
      },
      {
        title: 'Экскаватор Liebherr 956',
        avatar: lieb,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Ковш погрузочный" content="2м3" />
            <InfoLine title="Глубина копания" content="до 8м" />
          </>
        ),
      },
      {
        title: 'Экскаватор Hyundai 290',
        avatar: h290,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Ковш погрузочный" content="1.8м3" />
            <InfoLine title="Глубина копания" content="до 8м" />
          </>
        ),
      },
      {
        title: 'Экскаватор Hyundai 330',
        avatar: hyundai330,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Ковш погрузочный" content="1.8м3" />
            <InfoLine title="Глубина копания" content="до 8м" />
          </>
        ),
      },
      {
        title: 'Экскаватор Hyundai 330 (гидромолот)',
        avatar: hyundai330g,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Ковш погрузочный" content="1.8м3" />
            <InfoLine title="Глубина копания" content="до 8м" />
          </>
        ),
      },
      {
        title: 'Экскаватор Hyundai 300 (гидромолот)',
        avatar: h300g,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Ковш погрузочный" content="1.8м3" />
            <InfoLine title="Глубина копания" content="до 8м" />
          </>
        ),
      },
      {
        title: 'Экскаватор Hitachi 330',
        avatar: hit330,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Ковш погрузочный" content="1.8м3" />
            <InfoLine title="Глубина копания" content="до 8м" />
          </>
        ),
      },
      {
        title: 'Экскаватор Volvo EW180C',
        avatar: volvo180c,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Ковш погрузочный" content="1.3м3" />
            <InfoLine title="Глубина копания" content="до 6м" />
          </>
        ),
      },
    ],
  },
  {
    id: 'graider',
    title: 'Грейдер',
    avatar: graider,
    heroTitle: 'Аренда грейдера в Мурманске и области',
    categoryTitle: 'Арендовать грейдер',
    machines: [
      {
        title: 'Автогрейдер Volvo G946',
        quantity: 1,
        avatar: graider,
        infoBlock: (
          <>
            <InfoLine title="Грузоподъемность" content="23 т" />
          </>
        ),
      },
    ],
  },
  {
    id: 'pogruzchik',
    title: 'Погрузчики',
    avatar: l120f,
    heroTitle: 'Аренда погрузчика в Мурманске и области',
    categoryTitle: 'Арендовать погрузчик',
    machines: [
      {
        title: 'Погрузчик Liebherr L550',
        avatar: l550,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Объем ковша" content="3.5м3" />
            <InfoLine title="Вид ковша" content="погрузочный" />
          </>
        ),
      },
      {
        title: 'Погрузчик Volvo L120F',
        avatar: l120f,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Объем ковша" content="3.5м3" />
            <InfoLine title="Вид ковша" content="погрузочный" />
          </>
        ),
      },
      {
        title: 'Погрузчик Volvo L60F',
        avatar: l60f,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Объем ковша" content="2.5м3" />
            <InfoLine title="Вид ковша" content="погрузочный" />
            <InfoLine
              title="Доп. оборудование"
              content="щетка для подмитания"
            />
          </>
        ),
      },
      {
        title: 'Погрузчик SDLG 936L',
        avatar: sdlg,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Объем ковша" content="1.8м3" />
            <InfoLine title="Вид ковша" content="погрузочный" />
            <InfoLine
              title="Доп. оборудование"
              content="боковой отвал для снега"
            />
          </>
        ),
      },
      {
        title: 'Погрузчик CASE 3 в 1 (гидромолот)',
        avatar: case3,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Объем ковша для погрузки" content="1.2м3" />
            <InfoLine title="Объем ковша для копания" content="0.8м3" />
          </>
        ),
      },
    ],
  },
  {
    id: 'kran',
    title: 'Краны',
    avatar: kato2,
    heroTitle: 'Аренда крана в Мурманске и области',
    categoryTitle: 'Арендовать кран',
    machines: [
      {
        title: 'Автокран КАТО',
        avatar: kato,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Грузоподъемность кузова" content="30т" />
            <InfoLine title="Длина стрелы" content="32м" />
          </>
        ),
      },
    ],
  },
  {
    id: 'buldozer',
    title: 'Бульдозер',
    avatar: ural,
    heroTitle: 'Аренда бульдозера в Мурманске и области',
    categoryTitle: 'Арендовать бульдозер',
    machines: [
      {
        title: 'Бульдозер ДСТ Урал Д15',
        avatar: ural,
        quantity: 1,
        infoBlock: (
          <>
            <InfoLine title="Вес" content="25т" />
          </>
        ),
      },
    ],
  },
  {
    id: 'shalanda',
    title: 'Шаланда',
    avatar: sh,
    heroTitle: 'Аренда шаланда в Мурманске и области',
    categoryTitle: 'Арендовать шаланду',
    machines: [
      {
        title: 'Шаланда (без борта)',
        quantity: 2,
        avatar: sh,
        infoBlock: (
          <>
            <InfoLine title="Размер платформы" content="13м x 2.5м" />
            <InfoLine title="Грузоподъемность" content="40 т" />
          </>
        ),
      },
    ],
  },
  {
    id: 'trall',
    title: 'Трал',
    avatar: t1,
    heroTitle: 'Аренда трала в Мурманске и области',
    categoryTitle: 'Арендовать трал',
    machines: [
      {
        title: 'Трал',
        quantity: 2,
        avatar: t1,
        infoBlock: (
          <>
            <InfoLine title="Высота платформы" content="0.6м" />
            <InfoLine title="Размер платформы" content="7.5м" />
            <InfoLine title="Грузоподъемность" content="45 т" />
          </>
        ),
      },
    ],
  },
  {
    id: 'katok',
    title: 'Каток',
    avatar: katok,
    heroTitle: 'Аренда катка в Мурманске и области',
    categoryTitle: 'Арендовать каток',
    machines: [
      {
        title: 'Каток Sany',
        quantity: 1,
        avatar: katok,
        infoBlock: (
          <>
            <InfoLine title="Вес" content="20 т" />
          </>
        ),
      },
    ],
  },
]
