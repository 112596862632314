import { cloneDeep, find, isArray, isEmpty } from 'lodash'

import { fieldChanged } from './fieldChanged'

const fieldChangedHandler = ({
  fieldIdentifier,
  form,
  setForm,
  fieldset,
  customEvent,
}) => (event) => {
  const formClone = cloneDeep(form)
  let field

  if (isArray(formClone)) {
    field = find(formClone, ['fieldId', fieldIdentifier])
  } else if (fieldIdentifier) {
    field = formClone[fieldIdentifier]
  } else {
    field = formClone
  }

  if (!isEmpty(fieldset)) {
    field = find(field.values[fieldset.pos], ['fieldId', fieldset.fieldId])
  }

  const fieldsWithDropValueDependencies = Object.entries(formClone)
    .filter(
      ([k, v]) =>
        v.hasOwnProperty('render') && v.render.dropOnFieldIdValueChanged
    )
    .map(([k, v]) => v)

  const dependentField = find(
    fieldsWithDropValueDependencies,
    (f) => f.render.dropOnFieldIdValueChanged === fieldIdentifier
  )
  if (
    dependentField &&
    formClone[fieldIdentifier].value !== event.target.value
  ) {
    formClone[dependentField.fieldId].value = ''
  }

  fieldChanged({ field, event })
  setForm(formClone)
}

export default fieldChangedHandler
