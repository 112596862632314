import React from 'react'
import './ContactUs.sass'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import Icon from '../../ui/Icon/Icon'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import ContactUsForm from './ContactUsForm/ContactUsForm'
import ContactUsPopUp from './ContactUsPopUp'

function ContactUs() {
  return (
    <section id="contacts" className="ContactUs DefaultSection">
      <ContactUsPopUp />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Контакты" />
            <div className="ContactUs-Container">
              <div className="ContactUs-Info">
                <div className="ContactUs-Links">
                  <ContactUsInfoTile
                    icon="mobile"
                    data={
                      <>
                        <a href="tel:+7 (921) 162-80-50">+7 (921) 162-80-50</a>
                        <br />
                        <a href="tel:+7 (921) 270-25-80">+7 (921) 270-25-80</a>
                        <br />
                        <a href="tel:+7 (921) 272-03-23">+7 (921) 272-03-23</a>
                        {/* <br />
                        <a href="tel:+7 (921) 270-25-80">+7 (921) 270-25-80</a> */}
                      </>
                    }
                    description="Viber, Telegram, Whatsapp"
                    style={{ alignItems: 'start' }}
                  />
                  <ContactUsInfoTile
                    icon="map-marker-alt"
                    data="г. Мурманск"
                    description="Ленина 5, оф. 212"
                  />
                  <ContactUsInfoTile icon="clock" data="24/7" />
                  <ContactUsInfoTile
                    icon="envelope"
                    data="spectruck51@mail.ru"
                  />
                </div>
                <div className="ContactUs-Map">
                  <YMaps>
                    <Map
                      className="Map"
                      defaultState={{
                        center: [68.95127354943814,33.088886499999916],
                        zoom: 16,
                      }}
                    >
                      <Placemark
                        geometry={[68.95127354943814,33.088886499999916]}
                        options={{ preset: 'islands#redIcon' }}
                      />
                    </Map>
                  </YMaps>
                </div>
              </div>
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function ContactUsInfoTile({ icon, data, description, style }) {
  return (
    <div className="ContactUsInfoTile" style={style}>
      <div className="ContactUsInfoTile-Icon">
        <Icon name={icon} weight="solid" />
      </div>
      <div className="ContactUsInfoTile-Data">
        <p className="Data">{data}</p>
        {description && <p className="Description">{description}</p>}
      </div>
    </div>
  )
}
export default ContactUs
