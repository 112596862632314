import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Feeds.sass'
import Icon from '../../ui/Icon/Icon'
import google from './assets/google.svg'
import yandex from './assets/yandex.svg'

function Feeds() {
  return (
    <div id="feeds" className="Feeds DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Отзывы о нашей работе" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Feeds-Container">
              <div className="Counters">
                <FeedSystem img={google} rating={4.5} counter={61} />
                <FeedSystem img={yandex} rating={4.0} counter={45} />
              </div>
              <div className="Feeds-Carousel">
                <FeedBlock
                  name="Александра"
                  date="12.01.2022"
                  rating={5}
                  text="Давно искали погрузчик по адекватной цене.В этой компании нам удалось найти именно то, что нужно. Аренда погрузчика обошлась нам по выгодной цене. Мы всем довольны. Спасибо"
                />
                <FeedBlock
                  name="Дмитрий"
                  date="02.12.2021"
                  rating={5}
                  text="У нас небольшая строительная компания, необходимость в спецтехнике постоянная. Своей спецтехники мало, при необходимости обращаемся за помощью в СпецТрак. Ответственная компания, работать с ними удобно. Есть вся необходимая спецтехника, всегда в рабочем состоянии, адекватный и профессиональный персонал. Технику всегда подают пунктуально, независимо от геолокации и сезона. Стоимость аренды спецтехники адекватная, большое спасибо за сотрудничество, будем обращаться и в дальнейшем по необходимости."
                />
                <FeedBlock
                  name="Николай"
                  date="16.11.2021"
                  rating={5}
                  text="Спасибо, что прегласили оставить отзыв о вашей компании. Мы с вами работаем уже давно, плодотворность наших длительных отношений только радует. Пока не видим для себя актуальности в покупке собственной техники. Цена на ваши услуги более чем адекватна!"
                />
                <FeedBlock
                  name="Екатерина"
                  date="24.10.2021"
                  rating={5}
                  text="Срочно понадобилась техника, приняли заявку ночью, с утра техника была на объекте. Спасибо за оперативность, вы сэкономили нам кучу нервов и денег. Буду вас рекомендовать другим подрядчикам."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function FeedSystem({ img, rating, counter }) {
  return (
    <div className="FeedSystem-Block">
      <div className="Img">
        <img src={img} alt="FeedSystem" />
        <span>Отзывы</span>
      </div>
      <div className="Rating">
        {rating}
        <div className="Stars">
          <Icon name="star" weight="solid" />
          <Icon name="star" weight="solid" />
          <Icon name="star" weight="solid" />
          <Icon name="star" weight="solid" />
          <Icon name="star" weight="solid" />
        </div>
      </div>
      <p className="Counter">{counter} отзывов</p>
    </div>
  )
}

function FeedBlock({ name, date, text, rating }) {
  const stars = []

  for (let index = 0; index < 5; index++) {
    stars.push(
      <Icon
        name="star"
        weight="solid"
        style={index <= rating ? { color: '#FFC107' } : { color: '#B9C0C8' }}
      />
    )
  }

  return (
    <div className="FeedBlock">
      <div className="FeedBlock-Header">
        <span className="Name">{name}</span>
        <div className="Stars">{stars}</div>
      </div>
      <p className="Date">{date}</p>
      <p className="Text">{text}</p>
    </div>
  )
}

export default Feeds
