import React from 'react'
import './Calc.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import CalcForm from './CalcForm/CalcForm'
import InfoLine from '../../ui/InfoLine/InfoLine'
import a from '../Catalog/assets/a1.jpg'
import Image from '../../ui/Img/Img'
import Button from '../../ui/Button/Button'
import deco from '../Hero/whiteTri.svg'
import { useState } from 'react'
import { cats } from '../Catalog/data'

function Calc() {
  const [activeCatData, setActiveCatData] = useState(cats[0].machines[0])

  return (
    <section id="calc" className="Calc DefaultSection">
      <div className="DecoTri Tri1" style={{ background: `url(${deco})` }} />
      <div className="DecoTri Tri2" style={{ background: `url(${deco})` }} />
      <SectionTitle title="Калькулятор аренды" theme="white" />
      <div className="Calc-Container">
        <CalcForm activeCatData={setActiveCatData} />
        <div className="Calc-Data">
          <div className="Avatar">
            <img src={activeCatData.avatar} />
          </div>
          <div className="Content">
            <div className="Title">{activeCatData.title}</div>
            <div className="Info">{activeCatData.infoBlock}</div>
          </div>
          <div className="PriceLine"></div>
          <p className="Description">
            <span style={{ color: '#CE0000' }}>*</span> Стоимость аренды
            высчитывается примерно, согласно базовых тарифов нашей компании, но
            без учета деталей заказа. Для точного расчте стоимости аренды
            выбранной вами спецтехники вы можете оставить заявку, и наш менеджер
            свяжется с вами в ближайшее время
          </p>
        </div>
      </div>
    </section>
  )
}

export default Calc
