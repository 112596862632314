import React from 'react'
import './Hero.sass'
import { withRouter } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'
import Button from '../../ui/Button/Button'
import heroImg from './hero.png'
import heroImg2 from './test.jpg'
import Img from '../../ui/Img/Img'
import deco from './whiteTri.svg'

function Hero({ title }) {
  return (
    <div id="home" className="Site-Hero">
      <div className="Illustration">
        <Img src={heroImg2} alt="СпецТрак" />
      </div>
      <div className="Offer">
        <h1>
          Аренда спецтехники
          <br />
          <b>в Мурманске и области</b>
        </h1>
        <p className="Description">
          Возможно привлечения дополнительной необходимой техники, не
          представленой в каталоге
        </p>
        <div className="Offer-Benefits">
          <Benefit
            icon="clock"
            text="Работаем
Круглосуточно"
          />
          <Benefit
            icon="truck-container"
            text="Собственный
автопарк"
          />
          <Benefit
            icon="trophy-alt"
            text="Более 12 лет
на рынке"
          />
        </div>
        <div className="Buttons">
          <Button
            title="Каталог техники"
            theme="solid"
            fill="accent"
            type="hashlink"
            path="/#catalog"
          />
          <Button
            title="Рассчитать стоимость аренды"
            theme="solid"
            fill="accent"
            type="hashlink"
            path="/#calc"
          />
        </div>
      </div>
      <div className="DecoTri" style={{ background: `url(${deco})` }} />
    </div>
  )
}

function Benefit({ icon, text }) {
  return (
    <div className="Benefit">
      <Icon name={icon} weight="regular" />
      <span className="Text">{text}</span>
    </div>
  )
}

export default withRouter(Hero)
