import React, { useContext } from 'react'
import Icon from '../../../ui/Icon/Icon'
import { AuthContext } from '../../../App'
import './PhoneChooser.sass'

function PhoneChooser() {
  return (
    <div className="PhoneChooser">
      <div className="Container">
        <Icon name="user-headset" weight="regular" className="ChooserIcon" />
        <a href="tel:+79211628050">
          <span className="PhoneChooser-ActivePhone">+7 (921) 162-80-50</span>
        </a>
      </div>
      <p>spectruck51@mail.ru</p>
    </div>
  )
}

export default PhoneChooser
