import React, { useContext } from 'react'
import './About.sass'
import { AuthContext } from '../../App'
import Button from '../../ui/Button/Button'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Icon from '../../ui/Icon/Icon'
import aboutImg from './aboutimg.png'
import Img from '../../ui/Img/Img'

function About() {
  return (
    <section id="about" className="About DefaultSection">
      <SectionTitle title={'ООО СпецТрак'} />
      <AboutContent />
    </section>
  )
}

export const AboutContent = ({ title, description }) => {
  const { setShowContactUs } = useContext(AuthContext)

  const text = (
    <>
      <div className="Description-Facts">
        <p>
          Компания "ООО СпецТрак" более 12 лет оказывает услуги аренды
          спецтехники на территории Мурманска и области. Из обширного автопарка,
          клиент может выбрать необходимый транспорт, позволяющий реализовать
          поставленные задачи. У нас Вы найдёте гусеничные и колёсные
          эkckaвaторы с различным объёмом ковша, дорожно-строительную технику,
          сможете арендовать автокран с грузоподъёмностью от 25 до пятидесяти
          тонн, маниупулятор, бульдозер, низкорамник и другой транспорт.
        </p>
        <p>
          Услуги предоставляются юридическим лицам. Наши специалисты подберут
          машины, учитывая потребности клиента, специфику деятельности, что
          позволит оптимизировать технологический процесс, выполнить необходимый
          комплекс работ в установленные временные рамки и качественно.
          Воспользовавшись услугами аренды, Вы сэкономите материальные затраты
          на содержание и ремонт транспорта.
        </p>
        {/*
        <DescriptionLine
          icon="check"
          title="Раз в месяц – проводится частичное ТО (протяжка механизмов, шприцевание узлов)"
        />
        <DescriptionLine
          icon="check"
          title="Раз в 3 месяца – проводится полное ТО (предусмотренная регламентом замена масла, фильтров)"
        />
        <DescriptionLine
          icon="check"
          title="Если пробег техники за 3 месяца превышает 10 000 км, мы производим полное ТО сразу по достижении этой отметки."
        /> */}
        <div className="Buttons">
          <Button
            title="Оставить заявку на аренду"
            theme="solid"
            fill="accent"
          />
        </div>
      </div>
    </>
  )

  return (
    <div className="About-Container">
      <div className="AboutImg">
        <Img src={aboutImg} />
      </div>
      <div className="About-Info">
        <div className="Description">{description || text}</div>
      </div>
    </div>
  )
}

const DescriptionLine = ({ icon, title, text }) => (
  <div style={{ display: 'flex' }}>
    <Icon
      name={icon}
      style={{ marginTop: '4px', color: '#CE0000', fontSize: '18px' }}
      weight="solid"
    />
    <div style={{ marginLeft: '16px' }}>
      <p style={{ fontWeight: '400', marginBottom: '12px' }}>{title}</p>
      <p>{text}</p>
    </div>
  </div>
)

export default About
