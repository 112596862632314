import React, { useContext, useState } from 'react'
import './CalcForm.sass'
import InputRange from 'react-input-range'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import { createForm } from '../../../utils/newforms/createForm'
import FormRender from '../../../utils/newforms/render/FormRender'
import 'react-input-range/lib/css/index.css'
import Button from '../../../ui/Button/Button'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../../ui/ScrollToTop/scrollWithOffset'
import { AuthContext } from '../../../App'
import { cats } from '../../../components/Catalog/data'
import { find, flatten, split } from 'lodash'
import { useEffect } from 'react'
import usePrevious from '../../../hooks/usePrevious'

function CalcForm({ price, activeCatData }) {
  const [form, setForm] = useState(createForm({ formPattern: new Form(price) }))
  const { setShowContactUs } = useContext(AuthContext)
  const [rangeValue, setRangeValue] = useState(15)

  const prevTech = usePrevious(form)

  useEffect(() => {
    if (prevTech && prevTech.tech.value !== form.tech.value) {
      const [id, num] = split(form.tech.value, '.')
      const cat = find(cats, ['id', id])
      activeCatData(cat.machines[num])
    }
  }, [form])

  return (
    <div className="CalcForm">
      <h2>Оставить заявку на расчет</h2>
      <FormRender
        form={form}
        setForm={setForm}
        sections={[{ fields: ['tech', 'name', 'phone', 'email', 'comment'] }]}
        // errors={showErrors}
      />
      <Button
        title="Отправить"
        icon="envelope"
        iconPosition="right"
        theme="solid"
        fill="accent"
      />
    </div>
  )
}

class Form {
  constructor() {
    this.tech = {
      field: {
        fieldId: 'tech',
        fieldType: 'select',
        label: 'Выберите еденицу техники',
        getOptions: flatten(
          cats.map((c) =>
            c.machines.map((m, i) => ({
              label: m.title,
              value: `${c.id}.${i}`,
            }))
          )
        ),
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.name = {
      field: {
        fieldId: 'name',
        fieldType: 'input',
        inputType: 'text',
        label: 'Ваше имя',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        label: 'E-mail',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        label: 'Контактный телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.comment = {
      field: {
        fieldId: 'comment',
        fieldType: 'textarea',
        label: 'Комментарий',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default CalcForm
