import React, { useState, useContext, useEffect } from 'react'
import './ContactUsForm.sass'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utils/newforms/createForm'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import FormRender from '../../../utils/newforms/render/FormRender'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import { functions } from '../../../config/firebase'
import { AuthContext } from '../../../App'
import location from '../../../locale/location'

function ContactUsForm({
  subject = 'Обратная связь с сайта',
  additionalContent = null,
}) {
  const { lang } = useContext(AuthContext)
  const [form, setForm] = useState(
    createForm({ formPattern: new ContactForm(lang) })
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  useEffect(() => {
    setForm(createForm({ formPattern: new ContactForm(lang) }))
  }, [lang])

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: location[lang].contactForm.onError,
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })

    const func = functions.httpsCallable('emailSender')
    func({
      subject,
      to: 'iterra.work2@gmail.com',
      html: `
      Имя: ${values.name}<br/>
      E-mail: ${values.email}<br/>
      Номер телефона: ${values.phone.value}<br/>
      Комментарий: ${values.comment}<br/>
      ${additionalContent || ''}`,
    }).then(({ data }) => {
      if (data === 'success') {
        setStatusMessage({
          show: true,
          type: 'success',
          message: location[lang].contactForm.onSuccess,
          closeAfter: 5000,
        })
        setForm(createForm({ formPattern: new ContactForm(lang) }))
        return setIsLoading(false)
      }
    })
  }

  return (
    <div className="ContactUs-Form">
      <h5>{location[lang].contactForm.contactUs}</h5>
      <p>{location[lang].contactForm.description}</p>
      <form onSubmit={onSubmit}>
        <div className="Form-Fields">
          {statusMessage.show && (
            <StatusMessage
              className="Site-StatusMessage"
              type={statusMessage.type}
              message={statusMessage.message}
            />
          )}
          <FormRender
            form={form}
            setForm={setForm}
            sections={[{ fields: ['name', 'email', 'phone', 'comment'] }]}
            errors={showErrors}
          />
        </div>
        <div className="ContactUsForm-Buttons">
          <Button
            title={location[lang].contactForm.sendForm}
            theme="solid"
            fill="accent"
            iconWeight="solid"
            size={48}
            onClick={onSubmit}
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  )
}

class ContactForm {
  constructor(lang) {
    this.name = {
      field: {
        fieldId: 'name',
        fieldType: 'input',
        inputType: 'text',
        label: lang === 'ru' ? 'Ваше имя' : 'Name',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        label: 'E-mail',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        label: lang === 'ru' ? 'Контактный телефон' : 'Phone',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.comment = {
      field: {
        fieldId: 'comment',
        fieldType: 'textarea',
        label: lang === 'ru' ? 'Комментарий' : 'Comment',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default ContactUsForm
