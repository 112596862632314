import React from 'react'
import Button from '../../ui/Button/Button'
import Icon from '../../ui/Icon/Icon'
import InfoLine from '../../ui/InfoLine/InfoLine'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Catalog.sass'

import { Img } from 'react-image'
import { cats } from './data.js'
import { NavLink } from 'react-router-dom'

function Catalog() {
  return (
    <section id="catalog" className="Catalog">
      <div className="Catalog-Info">
        <SectionTitle title="Каталог техники" />
        <div className="Text">
          <Icon name="comment-alt-check" />
          <div className="Text-Container">
            <p>
              В нашем парке только современные, функциональные и надежные
              еденицы техники оборудованные <b>системой ГЛОНАС</b>.
            </p>

            <p>
              Благодаря регулярным проведениям всех предусмотренных регламентом
              проверок и обслуживанию Вы можете быть уверены в качестве
              выполнения ваших работ.
            </p>

            <p>
              Наш неизменный коллектив состоит из аттестованых, опытных и
              ответственных операторов, которые профессионально справятся со
              всеми поставленными Вами задачами.
            </p>
          </div>
        </div>
      </div>
      {cats.map((c) => (
        <CategoryTile {...c} />
      ))}
    </section>
  )
}

function CategoryTile({ id, avatar, title, machines }) {
  let quantity = 0
  machines.forEach((m) => (quantity += m.quantity))
  return (
    <NavLink to={`/arenda/${id}`}>
      <div className="CategoryTile">
        <div className="Avatar">
          <img src={avatar} />
          <span className="Quantity">{quantity} ед.</span>
        </div>
        <div className="CategoryTile-Container">
          <p className="Title">{title}</p>
          {/* <div className="Info">
          <InfoLine title="Объем кузова" content="от 20м3" />
          <InfoLine title="Грузоподъемность" content="от 20 т" />
        </div> */}
          <Button
            title="Выбрать технику"
            icon="arrow-right"
            iconPosition="right"
            theme="solid"
            fill="blue"
          />
        </div>
      </div>
    </NavLink>
  )
}

export default Catalog
