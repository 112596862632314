import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyDabDrOqgLpHkYnFCBrOm2G3IIb52dQFzg',
  authDomain: 'isee-spectruck.firebaseapp.com',
  projectId: 'isee-spectruck',
  storageBucket: 'isee-spectruck.appspot.com',
  messagingSenderId: '1035124998793',
  appId: '1:1035124998793:web:7cc9f11882e1e0da7cfc58',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
